import { Component, Vue } from 'vue-property-decorator'
import Navbar from '../../components/layout/Navbar.vue'
import { NotificationProgrammatic as Notification } from 'buefy'
import network from '@/services/Network'
import { namespace } from 'vuex-class'

const Auth = namespace('AuthModule')
@Component({
  components: {
    Navbar
  }
})
export default class Setting extends Vue {
    @Auth.Getter
    private userDetail!: any;

    private passwordUpdateResource = {
      old_password: '',
      new_password: ''
    };

    private passwordCheck = '';
    private isLoading = false;
    private userProfile: any = {};

    async created () {
      await this.getUserDetails()
    }

    async settingTabChanged (value: string | number) {
      if (value === 'profile_tab') {
        await this.getUserDetails()
      }
    }

    async getUserDetails () {
      try {
        this.isLoading = true
        const { data } = await network().get(`/employee/${this.userDetail.employee_id}`)
        this.userProfile = data.data
      } catch (error) { } finally {
        this.isLoading = false
      }
    }

    async resetPassword () {
      //* Validate password check
      // todo make something better really
      if (this.passwordUpdateResource.new_password.length < 5) {
        Notification.open({
          type: 'is-danger',
          message: 'New password cannot be less than 6 characters',
          duration: 15 * 1000,
          position: 'is-bottom-right'
        })
        return
      }
      if (this.passwordCheck !== this.passwordUpdateResource.new_password) {
        Notification.open({
          type: 'is-danger',
          message: 'New password does not match the repeat password',
          duration: 15 * 1000,
          position: 'is-bottom-right'
        })
        return
      }

      try {
        this.isLoading = true
        await network().post('/user/password/reset', this.passwordUpdateResource)
        Notification.open({
          type: 'is-success',
          message: 'Password updated successfully',
          duration: 15 * 1000,
          position: 'is-bottom-right'
        })
        this.passwordCheck = ''
        this.passwordUpdateResource = {
          old_password: '',
          new_password: ''
        }
      } catch (error) {
        Notification.open({
          type: 'is-danger',
          message: error.response.data.error,
          duration: 15 * 1000,
          position: 'is-bottom-right'
        })
      } finally {
        this.isLoading = false
      }
    }
}
