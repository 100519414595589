



































































































import Setting from './Setting.src'
export default Setting
